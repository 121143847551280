import React from "react";
import { Media } from "@ryerson/frontend.layout";
import { ContentSection, SplitConfig, Container } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { ButtonLink, Link } from "@ryerson/frontend.navigation";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import { useApplication } from "@ryerson/frontend.application";
import { useStaticQuery, graphql } from "gatsby";

export type FamilyOfCompaniesCompany = {
	imageUrl: string;
	url: string;
};

export type FamilyOfCompaniesContent = {
	title: string;
	subTitle: string;
	tagLine: string[];
	videoOverlay: string;
	videoId?: string;
	videoThumbnail: string;
	description: string;
	hideFamilies?: boolean;
	actionLabel?: string | string[];
	actionUrl?: string;
};

type ContentfulFamilyOfCompany = {
	companyName: string;
	slug: string;
	node_locale: string;
	companyType: {
		slug: string;
	};
	logo: {
		file: {
			url: string;
		};
	};
	id: string;
};

export type FamilyOfCompaniesProps = {
	content: FamilyOfCompaniesContent;
};

const MainContentSection = styled(ContentSection)`
	padding-top: 270px;
	padding-bottom: 100px;

`;

const ParallaxContainer = styled.div`
	position: absolute;
	left: 0;
	width: 100%;
	height: auto;
	z-index: 0;
`;

const LogoContainer = styled.div`
	width: 100%;
	height: auto;
	img {
		width: 100%;
		height: auto;
	}
`;

const TagLines = styled.div`
	width: 360px;
	height: auto;
	margin-left: auto;
	margin-top: 110px;
`;

const TagLinesSection = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 40px;
	height: 100%;
`;

const FamiliesContent = styled.div`
	width: 100%;
	max-width: 260px;
	height: auto;
`;

const FamiliesButtonLink = styled.div`
	width: 100%;
	margin-top: 25px;
	height: auto;
`;

const fadeInOutEffect = (direction:string) => `
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	width: 50px;
	pointer-events: none;
	z-index: 1;
	background: linear-gradient(${direction}, rgba(0, 0, 0, 0.7), transparent);
`;

const DesktopMarquee = styled.div`
	position: relative;
	display: flex;
	overflow: hidden;
	user-select: none;
	gap: 1rem;

	@keyframes scroll {
		from {
		transform: translateX(0);
		}
		to {
		transform: translateX(calc(-100% - 1rem));
		}
	}

	/* Left and Right fade-in/out effect */
	&:before {
		${fadeInOutEffect('90deg')};
		left: 0;
	}
	&:after {
		${fadeInOutEffect('270deg')};
		right: 0;
	}

	/* Pause animation on hover */
	&:hover .FOCMarqueeGroup {
		animation-play-state: paused;
	}
`;
const MobileMarquee = styled.div`
	display: block;
	width: 100%;
	overflow: hidden;
	position: relative;

	/* Left and Right fade-in/out effect */
	&:before {
		${fadeInOutEffect('to right')};
		left: 0;
	}

	&:after {
		${fadeInOutEffect('to left')};
		right: 0;
	}
`;
const MarqueeLogoGroup = styled.div`
	flex-shrink: 0;
	display: flex;
	justify-content: space-around;
	gap: 20px;
	animation: scroll 20s linear infinite;
`;

const TouchScrollParentContainer = styled.div`
	height: 100%;
	overflow-x: scroll;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
`;
const MobileMarqueeContainer = styled.div`
	display: flex;
	gap: 30px;
	width: fit-content;
	height: 100%;
	margin: 0;
	align-items: center;
	font-size: initial;
	line-height: normal;
`;

const LogoContainerStyle = styled.div`
	display: flex;
	width: 300px;
	height: 300px;
	justify-content: center;
	align-items: center;

	@media (min-width: 1000px) {
		&:hover img {
			transform: scale(1.2);
			transition: transform 0.3s ease;
		}
	};

	&:hover .FOCMarqueeGroup{
		animation-play-state: paused;
	}
`;

const LogoImage = styled.img`
	width: 200px;
	max-height: 100%;
	max-width: 100%;
	transition: transform 0.3s ease;
`;

const FamilyOfCompanies: React.FC<FamilyOfCompaniesProps> = ({ content }) => {
	const { theme } = useTheme();
	const {
		localization: { language },
	} = useApplication();
	const split: SplitConfig = {
		splitDirection: "vertical",
		splitColors: [
			{
				color: theme.colors.primary.darkerGray,
				percentage: "100%",
			},
		],
	};

	const queryData = useStaticQuery(graphql`
		query FoCCompanies {
			allContentfulFoCCompany {
				group(field: contentful_id) {
				edges {
					node {
					companyName
					slug
					node_locale
					companyType {
						slug
					}
					logo {
						file {
						url
						}
					}
					id
					}
				}
				}
			}
		}
	`);

	let contentfulFOCRawData = queryData.allContentfulFoCCompany;
	let familyOfCompanies: ContentfulFamilyOfCompany[] = [];

	contentfulFOCRawData.group.forEach((company: any) => {
        company.edges.forEach((edge: any) => {
            if (edge.node.node_locale.split("-").shift() === language) {
            	familyOfCompanies.push(edge.node);
            }
        });
    });
	
	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ParallaxProvider>
					<MainContentSection type="split" split={split} vPadding="270px">
						<ParallaxContainer>
							<Parallax y={["-80px", "-340px"]}>
								<Container
									css={css`
										background-color: transparent;
									`}
									vPadding="0"
								>
									<LogoContainer>
										<img src="/images/RyersonLogo.png" />
									</LogoContainer>
									<TagLines css={css`margin-top: 20px;`}>
										<TagLinesSection>
											<div>
												<Typography css={css`letter-spacing: -1.5px;`} variant="h2" color={theme.colors.primary.white}>{content.subTitle}</Typography>
											</div>
											<div>
												<FamiliesContent>
													<Typography
														color={theme.colors.primary.white}
														size="md"
													>
														{content.description}
													</Typography>
												</FamiliesContent>
											</div>
											<div>
												{content.actionUrl && content.actionLabel ? (
														<FamiliesButtonLink>
															<ButtonLink
																gatsby={true}
																to={content.actionUrl}
																label={content.actionLabel}
																type="tertiary"
															/>
														</FamiliesButtonLink>
													) : (
														<></>
													)}
											</div>
										</TagLinesSection>
					
									</TagLines>
								</Container>
							</Parallax>
						</ParallaxContainer>
						<img src={content.videoThumbnail} />
					</MainContentSection>

					<DesktopMarquee css={css`background-color: ${theme.colors.primary.primaryBrand};`}>
						<MarqueeLogoGroup className="FOCMarqueeGroup">
							{familyOfCompanies.length > 0 && familyOfCompanies.map((company, index) => (
								<LogoContainerStyle key={index}>
									<Link gatsby to={`/about-us/family-of-companies/${company.slug}`}>
										<LogoImage
											src={company.logo.file.url}
											alt={company.companyName}
										/>
									</Link>
								</LogoContainerStyle>
							))}
						</MarqueeLogoGroup>
						<MarqueeLogoGroup className="FOCMarqueeGroup" aria-hidden="true">
							{familyOfCompanies.length > 0 && familyOfCompanies.map((company, index) => (
								<LogoContainerStyle key={index}>
									<Link gatsby to={`/about-us/family-of-companies/${company.slug}`}>
										<LogoImage
											src={company.logo.file.url}
											alt={company.companyName}
										/>
									</Link>
								</LogoContainerStyle>
							))}
						</MarqueeLogoGroup>
					</DesktopMarquee>
				</ParallaxProvider>
			</Media>

			<Media lessThan="lg">
				<ContentSection type="primary" vPadding="30px" hPadding="0">
					<img
						src={content.videoThumbnail}
						css={css`
							width: 100%;
							height: auto;
						`}
					/>
					<Typography
						css={css`
							margin: 20px 0;
						`}
						variant="h2"
						color={theme.colors.primary.header}>
						{content.subTitle}
					</Typography>
					<Typography size="md">{content.description}</Typography>
					{content.actionLabel && content.actionUrl ? (
						<FamiliesButtonLink
							css={css`
								margin-bottom: 40px;
							`}
						>
							<ButtonLink
								type="primary"
								label={content.actionLabel}
								to={content.actionUrl}
								gatsby={true}
							/>
						</FamiliesButtonLink>
					) : (
						<></>
					)}
					<MobileMarquee
						css={css`
							background-color: ${theme.colors.primary.primaryBrand};
							height: 140px;
						`}
					>
						<TouchScrollParentContainer>
							<MobileMarqueeContainer>
								{familyOfCompanies.length > 0 && familyOfCompanies.map((company, index) => (
									<LogoContainerStyle key={index} css={css`width: 132px; height: 132px;`}>
										<Link gatsby to={`/about-us/family-of-companies/${company.slug}`}>
											<LogoImage
												src={company.logo.file.url}
												alt={company.companyName}
												css={css`width 100%; height: auto; max-height: 200px;`}
											/>
										</Link>
									</LogoContainerStyle>
								))}
							</MobileMarqueeContainer>
						</TouchScrollParentContainer>
					</MobileMarquee>
				</ContentSection>
			</Media>
		</>
	);
};

export default FamilyOfCompanies;
